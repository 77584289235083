import React, { useMemo } from "react";

import { Skeleton } from "antd";
import clsx from "clsx";

import { AppointmentV2 } from "~/utils/interfaces/Appointment";

const CONTACT_CHANNEL = {
  whatsapp: "Whatsapp",
  alliance: "Alianza",
  intercom: "Intercom",
  medical_representative: "Visitador médico",
  call_center: "Call center",
  marketing_campaing: "Campaña de marketing",
  medical_order: "Órden médica",
};

type AppointmentContactChannelProps = {
  isLoading?: boolean;
  appointment?: AppointmentV2 | null;
  className?: string;
};

function AppointmentContactChannel({ isLoading, appointment, className }: AppointmentContactChannelProps) {
  const contactChannel = useMemo(() => {
    if (appointment) {
      if (appointment?.contact_channel && appointment.contact_channel in CONTACT_CHANNEL) {
        return CONTACT_CHANNEL[appointment?.contact_channel];
      }
      return appointment?.contact_channel || "No especificado";
    }
    return "No especificado";
  }, [appointment]);

  return (
    <div className={clsx("col-span-1 flex flex-col", className)}>
      <label className="text-g3 font-medium text-sm whitespace-nowrap">Canal de contacto</label>
      {isLoading && (
        <Skeleton.Input
          active
          className="!h-6"
        />
      )}
      {!isLoading && appointment && <div className="select-all text-g1 text-md">{contactChannel}</div>}
      {!isLoading && !appointment && <div className="select-all text-g1 text-md">No especificado</div>}
    </div>
  );
}

export default AppointmentContactChannel;
