import { Payment } from "~/utils/interfaces/Appointment";

export function paymentMethodFormatter(payment: Payment): string {
  const translatePaymentMethod: Record<string, string> = {
    "Kushki Credit Card": "online con Tarjeta de Crédito",
    "MercadoPago Credit Card": "online con Tarjeta de Crédito",
    "MercadoPago Webpay Plus": "online por Webpay",
    "Exacoin Credits": "online con Exacoins",
    "Kushki Webpay Plus": "online por Webpay",
    "Debit Card": "Tarjeta de Débito",
    "Kushki In address": "en domicilio con botón de pago",
    "Cash In address": "en domicilio con efectivo",
    "Point of Sale Debit Card": "en terminal de pago (POS) con Tarjeta de Débito",
    "Point of Sale Credit Card": "en terminal de pago (POS) con Tarjeta de Crédito",
    "Bank Transfer": "Transferencia Bancaria",
    "Partner End of month": "Pago al final de mes",
    "Reward Reward": "rewards",
  };
  const paymentMethod = [payment.payment_gateway, payment.method].filter((x) => !!x).join(" ");

  if (!paymentMethod) {
    return "No se ha encontrado información de pago";
  }

  let paymentMethodResponse = translatePaymentMethod[paymentMethod];

  if (payment.gateway_identifier && payment.payment_gateway === "Point of Sale") {
    paymentMethodResponse = `${paymentMethodResponse} (Voucher: ${payment.gateway_identifier})`;
  }

  return paymentMethodResponse;
}
