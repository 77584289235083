import React, { useEffect, useState } from "react";

import { Popconfirm, Select, Skeleton, Tag, message, notification } from "antd";
import clsx from "clsx";

import { appointmentAPI } from "~/utils/api/v2";

const STATUS: Record<string, string> = {
  finished: "finalizado",
  notFinished: "no finalizado",
};

const TAG_STATUS: Record<string, Record<string, string>> = {
  confirmed: { name: "confirmada", color: "#44AF69" },
  finished: { name: "finalizada", color: "#039Be5" },
  "received-by-lab": { name: "recibido por laboratorio", color: "#BB342F" },
  cancelled: { name: "cancelada", color: "#BB342F" },
  other: { color: "#E2DADB" },
};

const OPTIONS = Object.entries(STATUS).map(([key, value]) => ({
  label: value,
  value: key,
}));

type AppointmentStatusSelectProps = {
  isLoading?: boolean;
  appointmentId?: string;
  apptStatus?: string;
  isFinished?: boolean;
  className?: string;
  onChange?: (value: boolean) => void;
};

function AppointmentFinishedSelect({
  isLoading: externalIsLoading,
  appointmentId,
  apptStatus,
  isFinished,
  className,
  onChange,
}: AppointmentStatusSelectProps) {
  const [selected, setSelected] = useState<string | undefined>();
  const [preSelected, setPreSelected] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  function handleChange(value: string) {
    setPreSelected(value);
    setOpen(true);
  }

  function handleOnCancelChange() {
    setOpen(false);
  }

  async function handleConfirmChange() {
    setIsLoading(true);
    try {
      await appointmentAPI.patch(appointmentId!, {
        finished: preSelected === "finished",
      });
      onChange?.(preSelected == "finished");
      setSelected(preSelected);
      message.success("Estado modificado");
    } catch (error) {
      notification.error({
        message: "No se pudo cambiar el estado",
        description: <div className="w-full text-g1">{(error as Error).toString()}</div>,
        duration: 10,
      });
    } finally {
      setOpen(false);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!externalIsLoading && typeof isFinished == "boolean") {
      setSelected(isFinished ? OPTIONS[0].value : OPTIONS[1].value);
    }
  }, [externalIsLoading, isFinished]);

  return (
    <div className={clsx("w-full flex flex-col gap-y-1", className)}>
      <label className="text-g3 font-medium text-sm">Estado</label>
      {externalIsLoading && (
        <Skeleton.Input
          active
          className="h-7"
        />
      )}
      {!externalIsLoading && (
        <div className="w-full flex gap-x-2">
          <Popconfirm
            title="Confirmar acción"
            description={
              <div className="font-light text-g2">
                ¿Cambiar el estado a <b className="text-g1 font-medium">{STATUS[preSelected!]}</b>?
              </div>
            }
            placement="top"
            open={open}
            okText="Sí, quiero cambiarlo"
            cancelText="Cancelar"
            okButtonProps={{ loading: isLoading, className: "bg-examedi-blue-strong" }}
            onConfirm={handleConfirmChange}
            onCancel={handleOnCancelChange}
            cancelButtonProps={{ disabled: isLoading }}
          >
            <Select
              value={selected}
              size="middle"
              options={OPTIONS}
              loading={isLoading}
              onChange={handleChange}
              labelRender={(item) => <div className="text-g1 first-letter:capitalize text-md">{item.label}</div>}
              optionRender={(item) => <div className="first-letter:capitalize">{item.label}</div>}
              className="w-full"
            />
          </Popconfirm>
          {isLoading && (
            <Skeleton.Input
              active
              className="!h-8 !min-w-0 !w-20"
            />
          )}
          {!isLoading && apptStatus && (
            <Tag
              className="flex items-center"
              style={{ backgroundColor: TAG_STATUS[apptStatus]?.color || TAG_STATUS["other"].color }}
            >
              <div
                className="first-letter:capitalize"
                style={{ color: TAG_STATUS[apptStatus] ? "white" : "black" }}
              >
                {TAG_STATUS[apptStatus]?.name || apptStatus}
              </div>
            </Tag>
          )}
        </div>
      )}
    </div>
  );
}

export default AppointmentFinishedSelect;
