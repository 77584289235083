import Paper from "@material-ui/core/Paper";
import styled from "styled-components";

export const Wrapper = styled(Paper)`
  padding: 4rem;
`;

export const SalesSource = styled.img`
  object-fit: contain;
  width: 70px;
  margin-left: 10px;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  align-items: center;

  .MuiButtonBase-root {
    margin: 5px 10px;

    span {
      pointer-events: none;
    }
  }
`;

export const CenteredItem = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`;

export const PointerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media only screen and (max-width: 1160px) {
    justify-content: center;
  }
`;

export const ReceiptCell = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

export const CommentCell = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

export const DeleteCommentButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;
