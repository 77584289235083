import React, { useEffect, useState } from "react";

import { Popconfirm, Select, Skeleton, message, notification } from "antd";
import clsx from "clsx";

import alliancesService from "~/utils/api/v1/alliancesService";
import { appointmentAPI } from "~/utils/api/v2";

type SelectType = {
  label: string;
  value: string;
};

type ClientOriginSelectProps = {
  appointmentId?: string;
  clientOrigin?: string | null;
  salesSource?: string;
  className?: string;
  onChange: (value: string | null) => void;
};

function ClientOriginSelect({
  appointmentId,
  clientOrigin,
  salesSource,
  className,
  onChange,
}: ClientOriginSelectProps) {
  const [selected, setSelected] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChangingValueLoading, setIsChangingValueLoading] = useState<boolean>(false);
  const [list, setList] = useState<SelectType[]>([]);
  const [preSelected, setPreSelected] = useState<string | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  function handleChange(value: string) {
    setPreSelected(value);
    setOpen(true);
  }

  function handleOnCancelChange() {
    setOpen(false);
  }

  async function handleConfirmChange() {
    let parsedNewValue: string | null = preSelected!;
    parsedNewValue = parsedNewValue === "no" ? null : parsedNewValue;
    setIsChangingValueLoading(true);
    try {
      await appointmentAPI.patch(appointmentId!, {
        client_origin: parsedNewValue,
      });
      onChange(parsedNewValue);
      message.success("Se cambió el client origin");
    } catch (error) {
      notification.error({
        message: "Ocurrió un error",
        description: "No se pudo establecer el client origin",
        duration: 10,
      });
    } finally {
      setOpen(false);
      setIsChangingValueLoading(false);
    }
  }

  useEffect(() => {
    if (salesSource) {
      (async function () {
        try {
          setIsLoading(true);
          const res = await alliancesService.fetchClientOrigins(salesSource);
          const data = res.data.origins.map((item) => ({
            label: item,
            value: item,
          }));
          setList([{ label: "-", value: "no" }, ...data]);
        } catch (err) {
          notification.error({ message: "Ocurrió un error", description: (err as Error).toString(), duration: 10 });
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [salesSource]);

  useEffect(() => {
    if (!isLoading && clientOrigin) {
      setSelected(clientOrigin);
    }
  }, [isLoading, clientOrigin]);

  return (
    <div className={clsx("w-full flex flex-col gap-y-1", className)}>
      <label className="text-g3 font-medium text-sm">Client origin</label>
      {isLoading && (
        <Skeleton.Input
          active
          className="!h-9"
        />
      )}
      {!isLoading && (
        <Popconfirm
          title="Confirmar acción"
          description={
            <div className="font-light text-g2">
              ¿Cambiar el client origin a <b className="text-g1 font-medium">{preSelected}</b>?
            </div>
          }
          placement="top"
          open={open}
          okText="Si, quiero cambiarlo"
          cancelText="Cancelar"
          okButtonProps={{ loading: isLoading, className: "bg-examedi-blue-strong" }}
          onConfirm={handleConfirmChange}
          onCancel={handleOnCancelChange}
          cancelButtonProps={{ disabled: isLoading }}
        >
          <Select
            value={selected}
            size="middle"
            loading={isChangingValueLoading}
            disabled={isChangingValueLoading}
            placeholder="Seleccione"
            optionFilterProp="children"
            options={list}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            labelRender={(item) => <div className="text-g1 first-letter:capitalize text-md">{item.label}</div>}
            optionRender={(item) => <div className="first-letter:capitalize">{item.label}</div>}
            onChange={handleChange}
            className="w-full"
          />
        </Popconfirm>
      )}
    </div>
  );
}

export default React.memo(ClientOriginSelect);
