import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

//components
import { Card, FormControlLabel, FormGroup, IconButton, List, ListItem, ListItemText, Switch } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleOutlined from "@material-ui/icons/AddCircleOutlined";
import styled from "styled-components";
import Swal from "sweetalert2";

import Flex from "~/components/Containers/Flex";
import useDebounce from "~/components/hooks/useDebounce";
import ServicesSearch from "~/components/Items/ServicesSearch";
import { appointmentPatientAPI, elasticSearchAPI } from "~/utils/api/v2";
import { SupportedCountry } from "~/utils/constants/places";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";
import { CartItemObjectType } from "~/utils/interfaces/cartItem";

const LoaderWrapper = styled.div`
  padding: 3rem;
`;

interface Props {
  appointmentPatient: AppointmentPatientV2;
  setAppointmentPatients: Dispatch<SetStateAction<AppointmentPatientV2[]>>;
  country: SupportedCountry;
  onlyPacks: boolean;
  salesSource?: string;
}

function AddServices(props: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isFonasa, setIsFonasa] = useState<boolean>(false);
  const [servicesToAdd, setServicesToAdd] = useState<CartItemObjectType[]>([]);
  const [query, setQuery] = useState<string>("");
  const [refetching, setRefetching] = useState<boolean>(false);
  const [availableServices, setAvailableServices] = useState<CartItemObjectType[]>([]);
  const [error, setError] = useState<string>("");
  const locale = props.country === "Chile" ? "cl" : "mx";

  const addServicesToPatient = async () => {
    setLoading(true);
    try {
      const items = servicesToAdd
        .filter((service) => !service.isPack())
        .map((item) => ({
          id: item.id,
          is_fonasa: item.allowFonasaPrice && isFonasa,
        }));
      const packs = servicesToAdd
        .filter((service) => service.isPack())
        .map((pack) => ({
          id: pack.id,
          is_fonasa: pack.allowFonasaPrice && isFonasa,
        }));
      if (items.length > 0) {
        await appointmentPatientAPI.checkSubservices(props.appointmentPatient.id, items[0].id);
      }
      const response = await appointmentPatientAPI.addServices(props.appointmentPatient.id, {
        items: items,
        packs: packs,
        created_from: "betty",
      });
      props.setAppointmentPatients((prev) => {
        const index = prev.indexOf(props.appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = response.data;
        return newAppointmentPatients;
      });
      await Swal.fire({
        title: "Actualizado!",
        icon: "success",
      });
      setOpen(false);
    } catch (err) {
      setError(err.response.data.err);
    }
    setLoading(false);
  };

  const fetchItems = async () => {
    setRefetching(true);
    const { data } = await elasticSearchAPI.newSearch({
      query,
      countryCode: locale,
      source: props.salesSource,
    });
    if (data) {
      setAvailableServices(data.filter((service) => (props.onlyPacks ? service.isPack() : !service.isPack())));
    }
    setRefetching(false);
  };

  const handleOpen = () => {
    if (availableServices.length === 0) {
      fetchItems();
    }
    setServicesToAdd([]);
    setOpen(true);
  };

  const handleClose = () => {
    setQuery("");
    setServicesToAdd([]);
    setOpen(false);
    setAvailableServices([]);
    setError("");
  };

  const invokeDebounce = useDebounce(async () => await fetchItems(), 300, {
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    invokeDebounce();
  }, [query]);

  const addService = (_index: number, newValue: CartItemObjectType[]) => {
    const newServices = newValue.filter(
      (newService) => !servicesToAdd.some((existingService) => existingService.id === newService.id),
    );
    setServicesToAdd((prevServices) => [...prevServices, ...newServices]);
  };

  return (
    <>
      <Card onClick={handleOpen}>
        <List>
          <ListItem>
            <ListItemText primary={`Añadir ${props.onlyPacks ? "packs" : "servicios"}`} />
            <IconButton aria-label="delete">
              <AddCircleOutlined />
            </IconButton>
          </ListItem>
        </List>
      </Card>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
      >
        <DialogTitle>Añadir {props.onlyPacks ? "pack" : "servicio"}</DialogTitle>
        <DialogContent>
          {loading && (
            <Flex
              direction="column"
              align="center"
            >
              <LoaderWrapper>
                <CircularProgress color="primary" />
              </LoaderWrapper>
            </Flex>
          )}
          {!loading && (
            <>
              <FormGroup style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() => setIsFonasa((val) => !val)}
                      checked={isFonasa}
                    />
                  }
                  label="Fonasa"
                />
              </FormGroup>
              <ServicesSearch
                options={availableServices}
                fonasa={isFonasa}
                index={0}
                query={query}
                setQuery={setQuery}
                refetching={refetching}
                addService={addService}
                showBasePrice={true}
                onlyPacks={props.onlyPacks}
              />
              {error && <p className="text-examedi-red-error text-sm my-3">Error, {error}</p>}
            </>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              onClick={addServicesToPatient}
              color="primary"
              disabled={loading}
            >
              Agregar
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}

export default AddServices;
