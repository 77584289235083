import React, { useState } from "react";

import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";

import { setCouponCodeToAppointment } from "~/utils/api/v2/appointment";
import { AppointmentV2 } from "~/utils/interfaces/Appointment";

type AddNewCouponProps = {
  appointmentId: string;
  setAppointment: (appointment: AppointmentV2) => void;
  appointment: AppointmentV2;
};

function AddNewCoupon({ appointmentId, setAppointment }: AddNewCouponProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleOpen = () => {
    setError(null);
    setCouponCode("");
    setOpenModal(true);
  };

  const handleSubmit = async () => {
    if (couponCode.length === 0) {
      setError("El código del cupón no puede estar vacío");
      return;
    }
    setLoading(true);
    const res = await setCouponCodeToAppointment(appointmentId, couponCode);
    if (res) {
      Swal.fire({
        title: "Cupón agregado",
        text: "El cupón se ha agregado correctamente",
        icon: "success",
      });
      setAppointment(res);
      setCouponCode("");
      setOpenModal(false);
    } else {
      setError("Cupón inválido");
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={handleOpen}
        className="border-top-left-radius-0 border-bottom-left-radius-0"
        disabled={loading}
      >
        {loading ? "Cargando..." : "Agregar Cupon"}
      </Button>
      <Dialog
        open={openModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <h3>Agregar cupón</h3>
          <IconButton
            className="absolute top-2.5 right-2.5"
            aria-label="close"
            onClick={() => setOpenModal(false)}
            edge="end"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="flex flex-col gap-y-2">
          <TextField
            id="couponCode"
            type="text"
            onChange={(e) => {
              setCouponCode(e.target.value.toUpperCase());
              setError("");
            }}
            value={couponCode}
            helperText="Código del cupón"
          />
          {error && <small className="text-examedi-red-error">{error}</small>}
          <ButtonGroup
            variant="outlined"
            color="primary"
            className="mt-4 flex justify-end"
          >
            <Button onClick={handleSubmit}>Agregar cupón</Button>
          </ButtonGroup>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddNewCoupon;
