import React, { useState, Fragment, useEffect } from "react";

import { Select, InputLabel, MenuItem, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

interface AppointmentPatientServiceProps {
  is_fonasa: boolean;
  insurance: string;
  appointmentPatientItemId?: number;
  changeItemInsurance: (appointmentPatientItemId: number, isFonasa: boolean, insurance: string) => void;
  changeAllItemsInsurance: (isFonasa: boolean, insurance: string) => void;
  mode: string;
}

function EditItemInsurance(props: AppointmentPatientServiceProps) {
  const [insurance, setInsurance] = useState(props.insurance);
  const [newInsurance, setNewInsurance] = useState(props.insurance);

  const saveNewInsurance = (newInsurance) => {
    const newIsFonasa = newInsurance === "fonasa";
    setInsurance(newInsurance);
    if (props.mode === "singleTest" && props.appointmentPatientItemId) {
      props.changeItemInsurance(props.appointmentPatientItemId, newIsFonasa, newInsurance);
    }
    if (props.mode === "allTests") {
      props.changeAllItemsInsurance(newIsFonasa, newInsurance);
    }
  };

  return (
    <Fragment>
      <FormControl
        fullWidth
        style={{ padding: "1rem", marginTop: "2rem" }}
      >
        <InputLabel
          id="insurance-label"
          style={{ marginLeft: "1rem" }}
        >
          {props.mode === "singleTest" && "Modificar Previsión"}
          {props.mode === "allTests" && "Modificar Previsión De Todos Los Exámenes"}
        </InputLabel>
        <Select
          labelId="insurance-label"
          value={newInsurance}
          onChange={(e) => setNewInsurance(e.target.value as string)}
          style={{ margin: "0" }}
        >
          <MenuItem value="particular">Particular</MenuItem>
          <MenuItem value="fonasa">Fonasa</MenuItem>
          <MenuItem value="cruzblanca">CruzBlanca</MenuItem>
          <MenuItem value="nueva_masvida">Nueva Masvida</MenuItem>
        </Select>
        <Button
          color="primary"
          variant="text"
          size="small"
          disabled={newInsurance === insurance && props.mode === "singleTest"}
          onClick={() => saveNewInsurance(newInsurance)}
        >
          Guardar
        </Button>
      </FormControl>
    </Fragment>
  );
}

export default EditItemInsurance;
