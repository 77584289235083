import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Swal from "sweetalert2";

import AddNewCoupon from "~/components/Appointments/AddNewCoupon";
import AddNewPayment from "~/components/Appointments/AddNewPayment";
import { Content, ContentTitleWrapper } from "~/components/Appointments/Styles/Content";
import Flex from "~/components/Containers/Flex";
import appointmentService from "~/utils/api/v1/appointmentService";
import paymentService from "~/utils/api/v2/payment";
import { AppointmentV2, Payment } from "~/utils/interfaces/Appointment";
import { numberWithPeriods } from "~/utils/numbers";
import { paymentMethodFormatter } from "~/utils/payments";

interface PaymentsProps {
  appointmentId: string;
  appointment: AppointmentV2;
  setAppointment: Dispatch<SetStateAction<AppointmentV2 | undefined>>;
  payments: Payment[];
  reloadPayments: () => void;
}

type PaymentCardProps = {
  setLoading: Dispatch<SetStateAction<boolean>>;
  appointmentId: string;
  payment: Payment;
  handleRegisterPayment?: () => void;
  updatePaymentsData: (ids: string[]) => void;
  payments: Payment[];
  reloadPayments?: () => void;
};

const PaymentCard = ({
  setLoading,
  appointmentId,
  payment,
  handleRegisterPayment,
  updatePaymentsData,
  payments,
  reloadPayments,
}: PaymentCardProps) => {
  const handleDeletePayment = async () => {
    const warningText = `Estás eliminando un pago${
      payment.status === "Payment Successful" ? " exitoso" : ""
    } de ${numberWithPeriods(payment.amount, "mx")}`;
    const check = await Swal.fire({
      title: "¿Estás seguro que deseas eliminar este pago?",
      text: warningText,
      icon: "warning",
      showCancelButton: true,
    });
    if (!check.isConfirmed) {
      return;
    }
    try {
      setLoading(true);
      await appointmentService.deletePayment(appointmentId, payment.id as string);
      await appointmentService.fitDetails(appointmentId);
      updatePaymentsData(payments.map((p) => p.id || "").filter((p) => p !== payment.id));
      await Swal.fire({
        title: "Pago eliminado",
        text: `El pago por ${payment.amount} ha sido eliminado con éxito`,
        icon: "success",
      });
    } catch (e) {
      await Swal.fire({
        title: "Error",
        text: `El pago no pudo ser eliminado. ${e.message}`,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteRefund = async () => {
    Swal.fire({
      title: "¿Estás seguro que deseas marcar este pago como reembolsado?",
      icon: "warning",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await paymentService.update(payment.id || "", {
          status: "Refund Completed",
        });
      }
      reloadPayments?.();
    });
  };

  if (payment.status === "Payment Pending") {
    return (
      <Flex
        direction="row"
        align="center"
      >
        <IconButton
          edge="start"
          size="small"
          aria-label="delete"
        >
          <DeleteIcon onClick={handleDeletePayment} />
        </IconButton>
        <p>Pago pendiente: {numberWithPeriods(payment.amount, "mx")}</p>
        <Button
          color="primary"
          onClick={handleRegisterPayment}
          variant="text"
        >
          Registrar pago
        </Button>
      </Flex>
    );
  }
  if (payment.status === "Refund Requested") {
    return (
      <Flex
        direction="row"
        align="center"
      >
        <IconButton
          edge="start"
          size="small"
          aria-label="delete"
        >
          <DeleteIcon onClick={handleDeletePayment} />
        </IconButton>
        <p>🚨 Pago por reembolsar: {numberWithPeriods(payment.amount, "mx")}</p>
        <Button
          color="primary"
          onClick={handleCompleteRefund}
          variant="text"
        >
          Reembolsar
        </Button>
      </Flex>
    );
  }
  if (payment.status === "Refund Completed") {
    return (
      <Flex
        direction="row"
        align="center"
      >
        <IconButton
          edge="start"
          size="small"
          aria-label="delete"
        >
          <DeleteIcon onClick={handleDeletePayment} />
        </IconButton>
        <p>✅ Pago reembolsado: {numberWithPeriods(payment.amount, "mx")}</p>
      </Flex>
    );
  }
  return (
    <Flex
      direction="row"
      align="center"
    >
      <IconButton
        edge="start"
        size="small"
        aria-label="delete"
      >
        <DeleteIcon onClick={handleDeletePayment} />
      </IconButton>
      <p>
        ✅ Pago exitoso: {numberWithPeriods(payment.amount, "mx")} - {paymentMethodFormatter(payment)}
      </p>
    </Flex>
  );
};

const Payments = ({ appointmentId, appointment, setAppointment, payments, reloadPayments }: PaymentsProps) => {
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
  const [currentPaymentData, setCurrentPaymentData] = useState<Payment>({
    id: null,
    amount: 0,
    method: "Cash",
    status: "Payment Pending",
    payment_gateway: "In Address",
    gateway_identifier: null,
    bank_name: null,
    document_number: null,
    receipt_image: null,
  });
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [pendingPayments, successfulPayments, refundRequestedPayments, refundCompletedPayments] = useMemo(() => {
    const tempPendingPayments: Payment[] = [];
    const tempSuccessfulPayments: Payment[] = [];
    const refundRequestedPayments: Payment[] = [];
    const refundCompletedPayments: Payment[] = [];
    payments.forEach((payment) => {
      if (payment.status === "Payment Pending") {
        tempPendingPayments.push(payment);
      } else if (payment.status === "Payment Successful") {
        tempSuccessfulPayments.push(payment);
      } else if (payment.status === "Refund Requested") {
        refundRequestedPayments.push(payment);
      } else if (payment.status === "Refund Completed") {
        refundCompletedPayments.push(payment);
      }
    });
    return [
      tempPendingPayments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
      tempSuccessfulPayments.sort((a, b) => new Date(b.paid_at) - new Date(a.paid_at)),
      refundRequestedPayments,
      refundCompletedPayments,
    ];
  }, [payments]);
  const handleRegisterPayment = (payment: Payment) => {
    setCurrentPaymentData(() => ({
      id: payment.id,
      amount: payment.amount,
      method: "In address",
      status: "Payment Pending",
      payment_gateway: "Cash",
      bank_name: null,
      document_number: null,
      receipt_image: null,
    }));
    setOpenPaymentModal(true);
  };

  const updatePaymentsIds = (ids: string[]) => {
    setAppointment((appointment) => {
      if (appointment) {
        return {
          ...appointment,
          payments: ids,
        };
      }
      return undefined;
    });
  };

  return (
    <Content>
      <ContentTitleWrapper>
        <h3>Pagos</h3>
        <div>
          <AddNewCoupon
            appointmentId={appointmentId}
            setAppointment={setAppointment}
            appointment={appointment}
          />
          <ButtonGroup
            color="primary"
            variant="text"
            size="small"
          >
            <AddNewPayment
              appointment={appointment}
              appointmentId={appointmentId}
              updatePaymentsData={updatePaymentsIds}
              modalOpen={openPaymentModal}
              paymentData={currentPaymentData}
              setPaymentData={setCurrentPaymentData}
              setOpenModal={setOpenPaymentModal}
            />
          </ButtonGroup>
        </div>
      </ContentTitleWrapper>
      {loadingPayment ? (
        <CircularProgress />
      ) : (
        <div>
          <Flex direction="column">
            {pendingPayments.map((payment) => (
              <PaymentCard
                appointmentId={appointmentId}
                setLoading={setLoadingPayment}
                payment={payment}
                handleRegisterPayment={() => handleRegisterPayment(payment)}
                updatePaymentsData={updatePaymentsIds}
                payments={payments}
              />
            ))}
            {pendingPayments.length > 0 && successfulPayments.length > 0 && <hr />}
            {successfulPayments.map((payment) => (
              <PaymentCard
                appointmentId={appointmentId}
                setLoading={setLoadingPayment}
                payment={payment}
                handleRegisterPayment={() => handleRegisterPayment(payment)}
                updatePaymentsData={updatePaymentsIds}
                payments={payments}
              />
            ))}
            {(!!refundRequestedPayments.length || !!refundCompletedPayments.length) && <h3>Reembolsos</h3>}
            {refundRequestedPayments.map((payment) => (
              <PaymentCard
                appointmentId={appointmentId}
                setLoading={setLoadingPayment}
                payment={payment}
                updatePaymentsData={updatePaymentsIds}
                payments={payments}
                reloadPayments={reloadPayments}
              />
            ))}
            {refundCompletedPayments.map((payment) => (
              <PaymentCard
                appointmentId={appointmentId}
                setLoading={setLoadingPayment}
                payment={payment}
                updatePaymentsData={updatePaymentsIds}
                payments={payments}
              />
            ))}
          </Flex>
          {pendingPayments.length === 0 && successfulPayments.length === 0 && <p>No hay pagos registrados</p>}
        </div>
      )}
    </Content>
  );
};

export default Payments;
