import React, { Dispatch, Fragment, SetStateAction } from "react";

import { Grid } from "@material-ui/core";
import { AxiosError } from "axios";

import PatientInformation from "~/components/Appointments/Detail/PatientInformation";
import { AppointmentV2 } from "~/utils/interfaces/Appointment";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";

interface PatientInformationListProps {
  appointmentPatients: AppointmentPatientV2[];
  setAppointmentPatients: Dispatch<SetStateAction<AppointmentPatientV2[]>>;
  appointmentId: string;
  country: string;
  appointment: AppointmentV2;
  setHasImage: Dispatch<SetStateAction<boolean>>;
  hasImage: boolean;
  setReloadOperativeData: Dispatch<SetStateAction<boolean>>;
  dealGroupedMedicalOrders: string[];
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  salesSource?: string;
  className?: string;
}

const PatientInformationList = ({
  appointmentPatients,
  setAppointmentPatients,
  country,
  appointmentId,
  hasImage,
  setHasImage,
  setReloadOperativeData,
  dealGroupedMedicalOrders,
  setError,
  salesSource,
  className,
}: PatientInformationListProps) => {
  return (
    <Fragment>
      {appointmentPatients.map((appointmentPatient, index: number) => {
        return (
          <Grid
            key={index}
            item
            xs={12}
            className="w-full"
          >
            <PatientInformation
              setError={setError}
              dealGroupedMedicalOrders={dealGroupedMedicalOrders}
              setAppointmentPatients={setAppointmentPatients}
              appointmentId={appointmentId}
              country={country}
              appointmentPatient={appointmentPatient}
              key={index}
              hasImage={hasImage}
              setHasImage={setHasImage}
              setReloadOperativeData={setReloadOperativeData}
              salesSource={salesSource}
              className={className}
            />
          </Grid>
        );
      })}
    </Fragment>
  );
};

export default PatientInformationList;
