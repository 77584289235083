import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import Swal from "sweetalert2";

import Collapsible, { CollapsibleForwardRefProps } from "~/components/Common/Collapsible";
import GreaterThanArrowIcon from "~/components/icons/GreaterThanArrowSmallIcon";
import { nursePaymentDetailDisplayName } from "~/components/nursePayments/constants";
import EditAppointmentNursePaymentModal from "~/components/nursePayments/EditAppointmentNursePaymentModal";
import { getAppointmentNursePayments } from "~/utils/api/v2/appointment";
import { listNursesPerCountry } from "~/utils/api/v2/nurse";
import { COUNTRY_TO_CODE } from "~/utils/data/constants";
import { NursePayment, NursePaymentDetail } from "~/utils/interfaces/nursePayments";
import { numberWithPeriods } from "~/utils/numbers";
import { capitalize } from "~/utils/texts";

type AppointmentNursePaymentCardProps = {
  appointmentId: string;
};

function AppointmentNursePaymentCard({ appointmentId }: AppointmentNursePaymentCardProps) {
  const ref = useRef<CollapsibleForwardRefProps | null>(null);
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState<boolean>(false);
  const [nursePayments, setNursePayments] = useState<NursePayment[]>([]);
  const [nursesNames, setNursesNames] = useState<Record<string, string>>();
  const [isEditPaymentModalOpen, setIsEditPaymentModalOpen] = useState<boolean>(false);
  const [toEditNursePayment, setToEditNursePayment] = useState<NursePayment | undefined>();

  const handleCollapsibleClick = () => {
    if (ref.current?.isOpen()) {
      ref.current?.close();
      return;
    }
    ref.current?.open();
  };

  const getAndSetNursePayments = async () => {
    const res = await getAppointmentNursePayments(appointmentId);
    if ("error" in res) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: res.error,
      });
      return;
    }
    setNursePayments(res);
  };

  useEffect(() => {
    getAndSetNursePayments();
  }, [appointmentId]);

  const getNurses = async () => {
    const nurseIds = nursePayments.map((nursePayment) => nursePayment.nurse);
    if (nurseIds.length) {
      const countryCode = COUNTRY_TO_CODE[nursePayments[0]?.appointment.country];
      const res = await listNursesPerCountry({ countryCode, nurseIds });
      if (!("error" in res)) {
        const namesDict: Record<string, string> = {};
        res.forEach((nurse) => (namesDict[nurse.id] = nurse.names + " " + nurse.last_names));
        setNursesNames(namesDict);
      } else {
        Swal.fire({
          title: "Error",
          text: "No se pudo obtener la información de las enfermeras. Intenta más tarde o contacta a soporte",
          icon: "error",
        });
      }
    }
  };

  const editPayment = (nursePayment: NursePayment) => {
    setToEditNursePayment(nursePayment);
    setIsEditPaymentModalOpen(true);
  };

  useEffect(() => {
    getNurses();
  }, [nursePayments]);

  return (
    <>
      <Collapsible
        head={
          <div
            className={clsx(
              "flex flex-row items-center justify-between",
              "px-3 py-2",
              "text-2xl font-semibold",
              "bg-g4 rounded-lg",
              "cursor-pointer",
              isCollapsibleOpen && "rounded-b-none",
            )}
            onClick={handleCollapsibleClick}
          >
            <p className="mb-0">Detalle de pagos HT</p>
            <GreaterThanArrowIcon
              height={18}
              width={12}
              className={isCollapsibleOpen ? "rotate-90" : "rotate-0"}
            />
          </div>
        }
        ref={ref}
        key="AppointmentNursePaymentCard"
        onChange={setIsCollapsibleOpen}
      >
        <div className={clsx("flex bg-g4 px-3 py-2 h-fit", isCollapsibleOpen && "rounded-b-lg")}>
          {nursePayments.length >= 0 &&
            nursePayments.map((nursePayment) => (
              <div
                className="flex flex-col w-full px-3"
                key={nursePayment.id}
              >
                <div className="flex flex-row justify-between">
                  <p className="mb-0 text-xl font-semibold">{nursesNames?.[nursePayment.nurse]}</p>
                  <button
                    className="uppercase text-sm text-c1"
                    onClick={() => editPayment(nursePayment)}
                  >
                    Editar Pago
                  </button>
                </div>
                <div
                  key="nursePaymentId"
                  className="flex py-2 text-md"
                >
                  <p className="mb-0">Id:</p>
                  <p className="mb-0 ml-3">{nursePayment.id}</p>
                </div>
                <div>
                  {Object.entries(nursePayment.detail)
                    .filter(([concept, amount]) => !!amount && concept !== "total")
                    .map(([concept, amount]) => (
                      <div
                        key={concept}
                        className="flex py-1"
                      >
                        <p className="mb-0 text-md">
                          {concept in nursePaymentDetailDisplayName
                            ? nursePaymentDetailDisplayName[concept as keyof NursePaymentDetail]
                            : capitalize(concept.replace(/_/g, " "))}
                          :
                        </p>
                        <p className="mb-0 ml-3 text-md">${numberWithPeriods(amount)}</p>
                      </div>
                    ))}
                  <div
                    key="total"
                    className="flex py-2 text-md"
                  >
                    <p className="mb-0 font-bold">Total:</p>
                    <p className="mb-0 font-bold ml-3">${numberWithPeriods(nursePayment.detail["total"])}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Collapsible>
      <EditAppointmentNursePaymentModal
        isOpen={isEditPaymentModalOpen}
        onClose={() => setIsEditPaymentModalOpen(false)}
        nursePayment={toEditNursePayment}
        refetchNursePayments={getAndSetNursePayments}
      />
    </>
  );
}

export default AppointmentNursePaymentCard;
