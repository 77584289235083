import React from "react";

import { Skeleton } from "antd";
import clsx from "clsx";
import { FaUserMd } from "react-icons/fa";

import ExternalLinkIcon from "~/components/icons/ExternalLinkIcon";
import { AppointmentV2 } from "~/utils/interfaces/Appointment";

type AppointmentHTCardProps = {
  isLoading?: boolean;
  appointment: AppointmentV2 | null;
  className?: string;
};

function AppointmentHTCard({ isLoading, appointment, className }: AppointmentHTCardProps) {
  return (
    <div className={clsx("rounded-md flex h-fit flex-col border-gray-100 gap-y-3", className)}>
      <div className="flex gap-x-3 items-center">
        <div className="w-full flex flex-col gap-y-1 text-right max-w-[10rem]">
          <div className="text-sm text-gray-3 whitespace-nowrap font-extralight">Profesional asignado</div>
          {isLoading && (
            <div className="w-full flex flex-col gap-y-2">
              <Skeleton.Input
                active
                size="small"
                className="!h-4"
              />
              <Skeleton.Input
                active
                size="small"
                className="!h-4"
              />
            </div>
          )}
          {!isLoading && appointment?.nurse_profile && (
            <div className="w-full text-gray-2 transition-colors leading-5 text-right">
              {appointment.nurse_profile.fullname}
            </div>
          )}
          {!isLoading && !appointment?.nurse_profile && (
            <div className="text-gray-2 whitespace-nowrap">No hay HT asignado</div>
          )}
        </div>
        {isLoading && (
          <Skeleton.Image
            active
            className="!h-32 !w-32"
          />
        )}
        {!isLoading && !appointment?.nurse_profile && (
          <div className="no-underline flex items-center justify-center bg-gray-5 rounded shrink-0 aspect-square h-32 w-32 group overflow-hidden">
            <FaUserMd className="h-24 w-24 fill-gray-4" />
          </div>
        )}
        {!isLoading && appointment?.nurse_profile && (
          <a
            href={"/health-team/" + appointment.nurse_profile.id}
            target="_blank"
            className="no-underline relative rounded shrink-0 aspect-square h-32 w-32 group overflow-hidden"
            rel="noreferrer"
          >
            <img
              alt="ht"
              src={appointment.nurse_profile.profile_picture}
              height={128}
              width={128}
              className="object-cover relative z-0 w-full h-full [overflow-clip-margin:unset]"
            />
            <div className="w-full z-10 absolute flex gap-x-1 justify-center items-center transition-transform ease-out group-hover:translate-y-0 py-1 bottom-0 translate-y-full bg-black/50">
              <div className="text-center text-white text-sm">Ir a perfil</div>
              <ExternalLinkIcon className="fill-white h-4 w-4" />
            </div>
          </a>
        )}
      </div>
    </div>
  );
}

export default AppointmentHTCard;
