import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Compressor from "compressorjs";
import styled from "styled-components";
import Swal from "sweetalert2";

import Flex from "~/components/Containers/Flex";
import appointmentService from "~/utils/api/v1/appointmentService";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface UploadImageProps {
  appointmentId: string;
  appointmentPatient: AppointmentPatientV2;
  hasImage: boolean;
  setHasImage: (hasImage: boolean) => void;
}

const UploadAndDisplayImage = (props: UploadImageProps) => {
  const [selectedImage, setSelectedImage] = useState<Blob | null>(null);
  const [loading, setLoading] = useState(false);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const [newImageURL, setNewImageURL] = useState<string>("");
  const { appointmentPatient } = props;
  const [pictures, setPictures] = useState<string[]>([]);

  useEffect(() => {
    if (appointmentPatient.pictures.length > 0) {
      setSuccessfulUpload(true);
      setPictures(appointmentPatient.pictures);
      props.setHasImage(true);
    }
  }, [appointmentPatient.pictures]);

  useEffect(() => {
    if (selectedImage) {
      setNewImageURL(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const uploadDocumentPic = async (): Promise<void> => {
    setLoading(true);
    const formData = new FormData();
    if (appointmentPatient.id) {
      formData.append("appointment_patient_id", appointmentPatient.id);
    }
    if (selectedImage) {
      formData.append("files", selectedImage);
    }

    const uploaded = await appointmentService.uploadRegistryImage(props.appointmentId, formData);

    if (uploaded) {
      setSuccessfulUpload(true);
      props.setHasImage(true);
      setPictures([...pictures, uploaded.data.url]);
      setNewImageURL("");
      Swal.fire({
        icon: "success",
        title: "Foto registrada",
      });
    } else {
      setSuccessfulUpload(false);
      Swal.fire({
        icon: "error",
        title: "La foto no se pudo subir",
      });
    }
    setLoading(false);
  };

  const changeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];

    if (!file) {
      Swal.fire({
        icon: "error",
        title: "Por favor selecciona una imagen antes de intentar subirla",
      });

      return;
    }

    if (file.size > 1_000_000) {
      new Compressor(file, {
        quality: 0.2,
        success(result: File) {
          const compressedFile = new File([result], result.name);
          setSelectedImage(compressedFile);
          props.setHasImage(false);
          setSuccessfulUpload(false);
        },
        error(err) {
          console.error(err);
          Swal.fire({
            icon: "error",
            title: "Hubo problemas con la imagen",
          });
        },
      });
    } else {
      setSelectedImage(file);
      props.setHasImage(false);
      setSuccessfulUpload(false);
    }
  };

  return (
    <div>
      {(selectedImage || appointmentPatient.picture) && (
        <div>
          <Flex
            direction="column"
            gap="0.5rem"
          >
            {pictures.map((picture, index) => (
              <ImgContainer key={index}>
                <img
                  style={{ cursor: "pointer", marginRight: "auto" }}
                  src={picture}
                  alt="action register"
                  height="150px"
                  onClick={() => window.open(picture, "_blank")}
                  title="Click para ver la imagen completa"
                />
              </ImgContainer>
            ))}
            {newImageURL && (
              <div className="p-2 bg-green-200 rounded max-w-fit border-zinc-500 border-[1px]">
                <p>Imagen a subir</p>
                <img
                  style={{ cursor: "pointer", marginRight: "auto" }}
                  src={newImageURL}
                  alt="action register"
                  height="150px"
                  onClick={() => window.open(newImageURL, "_blank")}
                  title="Click para ver la imagen completa"
                />
              </div>
            )}
            {!successfulUpload && (
              <Button
                color="secondary"
                variant="contained"
                disabled={loading}
                onClick={uploadDocumentPic}
              >
                {loading ? "Cargando..." : "Subir Foto"}
              </Button>
            )}
          </Flex>
        </div>
      )}
      <Grid
        container
        spacing={2}
        className="mt-1"
      >
        <Grid
          item
          xs={12}
        >
          <InputLabel htmlFor={`upload-photo-${appointmentPatient.id}`}>
            <Button
              color="primary"
              variant="contained"
              component="span"
            >
              {selectedImage || appointmentPatient.picture ? "Tomar otra foto" : "Tomar foto"}
            </Button>

            <Input
              type="file"
              id={`upload-photo-${appointmentPatient.id}`}
              name="upload-photo"
              style={{ display: "none" }}
              disabled={loading}
              inputProps={{ accept: "image/*" }}
              onChange={changeFile}
            />
          </InputLabel>
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadAndDisplayImage;
