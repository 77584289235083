import React from "react";

import { Avatar } from "@material-ui/core";

interface Props {
  nurse?: {
    fullname: string;
    profile_picture: string;
  };
}

function HTCard(props: Props): JSX.Element {
  const nurse = props.nurse;

  return (
    <div className="rounded-md flex h-fit flex-col bg-black/5 px-3 pt-2 pb-3 border border-gray-100 gap-y-3">
      <div className="text-sm text-gray-3">Será atendido por:</div>
      {nurse && (
        <div className="flex gap-x-3 items-center">
          <Avatar
            alt="ht"
            src={nurse.profile_picture}
            className="h-14 w-14 shadow-sm border border-white"
          />
          <div className="w-full text-gray-2">{nurse.fullname}</div>
        </div>
      )}
      {!nurse && <div className="w-full">No hay HT asignado</div>}
    </div>
  );
}

export default HTCard;
