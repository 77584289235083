import React, { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import { type AxiosResponse } from "axios";
import clsx from "clsx";
import { AiOutlineFilePdf } from "react-icons/ai";
import Swal from "sweetalert2";

import { FileCell, ImgContainer, UploadItem } from "~/components/Appointments/Styles/UploadImages";
import CheckCircleGreenFilledIcon from "~/icons/CheckCircleGreenFilledIcon";
import PlusCircleBlueIcon from "~/icons/PlusCircleBlueIcon";
import TrashIcon from "~/icons/TrashIcon";
import appointmentService from "~/utils/api/v1/appointmentService";
import { medicalOrderAPI } from "~/utils/api/v2";
import { postGroupedMedicalOrder } from "~/utils/api/v2/groupedMedicalOrder";
import { postMedicalOrder } from "~/utils/api/v2/medicalOrder";
import { compressFile } from "~/utils/files";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";
import { MedicalOrder } from "~/utils/interfaces/MedicalOrder";

type UploadImageProps = {
  groupedMedicalOrders: string[];
  setGroupedMedicalOrders: Dispatch<SetStateAction<string[]>>;
  appointmentPatient: AppointmentPatientV2;
  hasImage?: boolean;
  setHasImage?: (hasImage: boolean) => void;
  fileArray: File[] | undefined;
  setFileArray: React.Dispatch<React.SetStateAction<File[] | undefined>>;
  appointmentId: string;
};

const FileUploadForm = ({
  groupedMedicalOrders,
  setGroupedMedicalOrders,
  appointmentPatient,
  fileArray,
  setFileArray,
  appointmentId,
}: UploadImageProps) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [medicalOrders, setMedicalOrders] = useState<MedicalOrder[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const uploadMedicalOrderFiles = async (): Promise<void> => {
    setUploading(true);
    let firstGroupID = "";
    if (groupedMedicalOrders.length == 0) {
      try {
        const res = await postGroupedMedicalOrder("betty", appointmentPatient.id);

        firstGroupID = res.data.id;
        setGroupedMedicalOrders([res.data.id]);
      } catch (err) {
        await Swal.fire({
          icon: "error",
          title: "Error al subir las órdenes médicas",
        });

        setUploading(false);
        return;
      }
    }

    if (!fileArray) {
      await Swal.fire({
        icon: "error",
        title: "Por favor seleccione al menos un archivo antes de intentar subirlos",
      });

      setUploading(false);
      return;
    }

    const settledMedicalOrderResponses = await Promise.allSettled(
      fileArray.map(async (file) =>
        postMedicalOrder(file, groupedMedicalOrders[0] || firstGroupID, "betty", appointmentPatient.id),
      ),
    );

    const rejectedMedicalOrderResponses = settledMedicalOrderResponses.filter((res) => res.status === "rejected");
    const fulfilledMedicalOrderResponses = settledMedicalOrderResponses.filter((res) => res.status === "fulfilled");

    if (fulfilledMedicalOrderResponses.length === 0) {
      await Swal.fire({
        icon: "error",
        title: "Error al subir ordenes medicas, por favor inténtalo de nuevo",
      });

      setUploading(false);
      return;
    }

    const allUploaded = rejectedMedicalOrderResponses.length === 0;
    await Swal.fire({
      icon: allUploaded ? "success" : "warning",
      title: allUploaded
        ? "Órdenes médicas subidas correctamente"
        : "Una o más órdenes médicas no pudieron ser subidas",
    });

    setMedicalOrders((prev) => [
      ...prev,
      ...fulfilledMedicalOrderResponses.map((res) => (res.value as AxiosResponse<MedicalOrder>).data),
    ]);

    setFileArray([]);
    setUploading(false);
  };

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !fileArray) {
      setUploading(false);
      return;
    }

    for (const file of event.target.files) {
      if (file.size > 1_000_000 && file.type === "application/pdf") {
        Swal.fire({
          icon: "error",
          title:
            "Lo sentimos, no podemos procesar archivos pdf de más de 1mb. Prueba subiendo una foto o comprimiendo el archivo pdf.",
        });

        return;
      }
    }

    setUploading(true);

    const filesToCompress: Promise<File>[] = [];
    const filesToUpload: File[] = [];
    for (const file of event.target.files) {
      if (file.size > 1_000_000 && file.type !== "application/pdf") {
        filesToCompress.push(compressFile(file));
        continue;
      }

      filesToUpload.push(file);
    }

    try {
      const compressedFiles = await Promise.all(filesToCompress);
      filesToUpload.push(...compressedFiles);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error al comprimir los archivos, por favor inténtalo de nuevo",
      });

      setUploading(false);
      return;
    }

    setFileArray((prevState) => [...(prevState || []), ...filesToUpload]);
    setUploading(false);
  };

  const removeUploadedFile = (index: number) => {
    setFileArray((prevState) => prevState?.filter((_, idx) => idx !== index));
  };

  const handleClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleAppointmentUpdate = async () => {
    await appointmentService.updateAppointment(appointmentId, {});
  };

  const renderFileUploadProgressBar = () => (
    <div>
      <div className="flex flex-col gap-2 mb-2">
        {fileArray &&
          fileArray.map((file, i) => (
            <UploadItem key={i}>
              {!uploading && <CheckCircleGreenFilledIcon />}
              <p className={clsx(uploading ? "text-secondary" : "text-success")}>
                {uploading ? "Subiendo" : "Completado"}
              </p>
              <p>{file.name.length > 70 ? `${file.name.substring(0, 70)}...` : file.name}</p>
              {!uploading && (
                <Button
                  color="default"
                  onClick={() => removeUploadedFile(i)}
                >
                  <TrashIcon color="#BDBDBD" />
                </Button>
              )}
            </UploadItem>
          ))}
      </div>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        Subir otros archivos
        <PlusCircleBlueIcon
          width={20}
          height={20}
          fill="#2F80ED"
        />
      </Button>
      <input
        type="file"
        accept="application/pdf, image/*"
        disabled={uploading}
        onChange={handleFileInputChange}
        ref={inputRef}
        hidden
      />
    </div>
  );

  useEffect(() => {
    const fetchMedicalOrders = async () => {
      try {
        const response = await medicalOrderAPI.list(groupedMedicalOrders, appointmentPatient.id);
        setMedicalOrders(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (groupedMedicalOrders.length > 0 && appointmentPatient.id) {
      fetchMedicalOrders();
    }
  }, [groupedMedicalOrders, appointmentPatient.id]);

  return (
    <div>
      {medicalOrders.map((medicalOrder, i: number) => {
        const id = medicalOrder.id;
        const link = medicalOrder.appointment_file_link;
        return (
          <Fragment key={id}>
            {!link.includes("pdf") && (
              <ImgContainer>
                <img
                  style={{
                    cursor: "pointer",
                    marginBottom: "10px",
                    marginTop: "10px",
                    marginRight: "auto",
                  }}
                  src={link}
                  alt="action register"
                  height="150px"
                  onClick={async () => {
                    await handleAppointmentUpdate();
                    window.open(link, "_blank");
                  }}
                  title="Click para ver la imagen completa"
                />
              </ImgContainer>
            )}
            {link.includes("pdf") && (
              <a
                target="_blank"
                href={link}
                rel="noreferrer"
                onClick={async () => {
                  await handleAppointmentUpdate();
                }}
              >
                <FileCell>
                  <AiOutlineFilePdf size={38} />
                  <p>Archivo {i + 1}</p>
                </FileCell>
              </a>
            )}
          </Fragment>
        );
      })}
      {fileArray?.length == 0 && (
        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Seleccionar archivos
          </Button>
          <input
            type="file"
            accept="application/pdf, image/*"
            disabled={uploading}
            onChange={handleFileInputChange}
            ref={inputRef}
            multiple
            hidden
          />
        </div>
      )}
      {fileArray && fileArray?.length > 0 && renderFileUploadProgressBar()}
      <Button
        color="secondary"
        variant="contained"
        disabled={uploading || (fileArray && fileArray.length === 0)}
        onClick={uploadMedicalOrderFiles}
      >
        {uploading ? "Cargando..." : "Subir archivo(s)"}
      </Button>
    </div>
  );
};

export default React.memo(FileUploadForm);
