import React from "react";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const PaymentDetails = ({ data }) => {
  // Define the columns you want to display
  const columns = [
    "id",
    "status",
    "paid_at",
    "payment_gateway",
    "copago",
    "method",
    "item_name",
    "insurance",
    "gateway_identifier",
    "insurance",
    "patient_identifier",
    "pack_id",
    "lab_id",
    "original_price",
    "promotion_discount_applied",
    "afecto",
    "service_fee",
    "coupon_discount_applied",
    "coupon_applied",
    "pack_discount",
    "insurance_bonus",
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column == "id" ? "payment_id" : column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) =>
            row.payment_details.map((paymentDetail, paymentIndex) => (
              <TableRow key={`${rowIndex}-${paymentIndex}`}>
                {columns.map((column) => (
                  <TableCell key={column}>{column in row ? row[column] : paymentDetail[column]}</TableCell>
                ))}
              </TableRow>
            )),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentDetails;
