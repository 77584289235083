import React, { useEffect, useState } from "react";

import { Button, styled, Card } from "@material-ui/core";
import { navigate } from "gatsby-link";

import { Content } from "~/components/Appointments/Styles/Content";
import UploadOperativeExtraFiles from "~/components/Appointments/UploadOperativeExtraFiles";
import Flex from "~/components/Containers/Flex";
import appointmentService from "~/utils/api/v1/appointmentService";
import { OperativeAppointmentData } from "~/utils/interfaces/Appointment";

const NurseCard = styled(Card)({
  maxWidth: "25rem",
  marginBottom: "1rem",
});

function OperativeData({
  id,
  reload,
  setReload,
}: {
  id: string;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [operativeData, setOperativeData] = useState<OperativeAppointmentData | null>(null);
  const [loading, setLoading] = useState(true);
  const pStyle = { marginTop: "0.15rem", marginBottom: "0.15rem" };

  useEffect(() => {
    if (reload) {
      appointmentService.getOperativeData(id).then((res) => {
        setOperativeData(res.data.data);
      });
      setLoading(false);
      setReload(false);
    }
  }, [reload]);

  const formatAmountWithDots = (amount: number) => {
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return (
    <Content>
      <Flex justify="space-between">
        <h3 style={{ marginBottom: "0.5rem" }}>Operative Data</h3>
        {operativeData && operativeData.appointment && (
          <Button
            color="primary"
            variant="text"
            size="small"
            onClick={() => {
              navigate(`/appointment/${operativeData.appointment}/operative-edit?return_url=${location.pathname}`, {
                state: operativeData,
              });
            }}
          >
            &nbsp;Corregir datos&nbsp;
          </Button>
        )}
      </Flex>
      {!loading && operativeData && (
        <Flex direction="column">
          <Flex direction="column">
            <p style={pStyle}>Nombre: {operativeData.name}</p>
            <p style={pStyle}>Lead: {operativeData.lead}</p>
            <p style={pStyle}>Seller: {operativeData.seller.full_name}</p>
            <p style={pStyle}>Precio base: {operativeData.base_price}</p>
            <p style={pStyle}>Precio por persona: {operativeData.person_price}</p>
          </Flex>
          <Flex direction="column">
            <h4 style={{ marginBottom: "0.3rem", marginTop: "0.5rem" }}>Company</h4>
            <p style={pStyle}>Name: {operativeData.company.name}</p>
            {operativeData.company.document_id && <p style={pStyle}>RUT: {operativeData.company.document_id}</p>}
            <p style={pStyle}>Contacto: {operativeData.company.contact_name}</p>
            <p style={pStyle}>Email: {operativeData.company.contact_email}</p>
            <p style={pStyle}>Phone: {operativeData.company.contact_phone}</p>
          </Flex>
          <Flex direction="column">
            <h4 style={{ marginBottom: "0.3rem", marginTop: "0.5rem" }}>Pago</h4>
            <p style={pStyle}>Monto: {formatAmountWithDots(operativeData.payment.amount)}</p>
            <p style={pStyle}>Status: {operativeData.payment.status}</p>
            <p style={pStyle}>N° de Documento: {operativeData.payment.gateway_identifier}</p>
          </Flex>
          <Flex direction="column">
            <h4 style={{ marginBottom: "0.3rem", marginTop: "0.5rem" }}>HT's</h4>
            {operativeData.appointment_nurses.map((appointment_nurse) => (
              <NurseCard key={appointment_nurse.id}>
                <p style={pStyle}>Nombre: {appointment_nurse.nurse.user.full_name}</p>
                <p style={pStyle}>Pago bruto: {appointment_nurse.gross_ht_payment}</p>
              </NurseCard>
            ))}
          </Flex>
          {operativeData.company.name === "cchc" && (
            <UploadOperativeExtraFiles
              appointmentId={id}
              source={operativeData.company.name}
            />
          )}
        </Flex>
      )}
    </Content>
  );
}

export default OperativeData;
