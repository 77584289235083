import React from "react";

import styled from "styled-components";

import Bold from "~/components/Typography/Bold";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";

const TotalPackWrap = styled.div`
  width: 100%;
  margin-top: 20px;
  display: inline-block;
  background-color: whitesmoke;
`;

interface TotalpackPatientInfoProps {
  appointmentPatient: AppointmentPatientV2;
}

const TotalpackPatientInfo = ({ appointmentPatient }: TotalpackPatientInfoProps) => {
  return (
    <TotalPackWrap>
      <h3>Totalpack</h3>
      <p
        style={
          !appointmentPatient.active
            ? { color: "#D1CED1" }
            : appointmentPatient.totalpack_request?.status !== "connected-successfully"
            ? { color: "red" }
            : {}
        }
      >
        <Bold>Estado: </Bold> {appointmentPatient.totalpack_request?.status === "without-connection" && "Sin conexión"}
        {appointmentPatient.totalpack_request?.status === "connected-successfully" && "Conectado correctamente"}
        {appointmentPatient.totalpack_request?.status === "valuation-error" && "Error de valoración"}
        {appointmentPatient.totalpack_request?.status === "payment-confirmation-error" &&
          "Error de confirmación de pago"}
        {appointmentPatient.totalpack_request?.status === "closed-appointment" && "Cita cerrada"}
      </p>
      <p
        style={
          !appointmentPatient.active
            ? { color: "#D1CED1" }
            : !["connected-successfully", "closed-appointment"].includes(
                appointmentPatient.totalpack_request?.status || "",
              )
            ? { color: "red" }
            : {}
        }
      >
        <Bold>Mensaje: </Bold>
        {appointmentPatient.totalpack_request?.message}
      </p>
      {appointmentPatient.copago !== null && <p>Último copago calculado: {appointmentPatient.copago}</p>}
    </TotalPackWrap>
  );
};

export default TotalpackPatientInfo;
