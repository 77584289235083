export const bankOptionsCL = [
  "Banco de Chile",
  "Banco Internacional",
  "Banco Estado",
  "Scotiabank",
  "BCI",
  "Corpbanca",
  "HSBC",
  "BICE",
  "Santander",
  "Itau",
  "Banco Security",
  "Banco Falabella",
  "Banco Ripley",
  "Banco Consorcio",
  "BBVA",
  "Banco del Desarrollo",
  "Banco Coopeuch",
  "Los Héroes",
  "Tenpo",
  "Mercado Pago",
];

export const bankOptionsMX = [
  "BBVA",
  "Banorte",
  "Banamex",
  "Banco Azteca",
  "Scotiabank",
  "Santander",
  "HSBC",
  "Bancoppel",
  "Otro",
];

export const bankOptionsValues = {
  cl: bankOptionsCL.map((bankOption) => ({ name: bankOption, value: bankOption })),
  mx: bankOptionsMX.map((bankOption) => ({ name: bankOption, value: bankOption })),
};

/**
 * For the list paymentOptions, if the locale of the option is chile
 * then it implies that it is available for both countries
 */

export const paymentOptions = [
  {
    name: "Efectivo",
    value: "Cash",
    locale: "chile",
  },
  {
    name: "Terminal de pago (POS)",
    value: "Point of Sale",
    locale: "méxico",
  },
  {
    name: "MercadoPago",
    value: "MercadoPago",
    locale: "chile",
  },
  {
    name: "Kushki",
    value: "Kushki",
    locale: "méxico",
  },
  {
    name: "Exacoins",
    value: "Exacoin",
    locale: "méxico",
  },
  {
    name: "Transferencia bancaria",
    value: "Bank",
    locale: "chile",
  },
  {
    name: "Pago a fin de mes",
    value: "Partner",
    locale: "chile",
  },
];
