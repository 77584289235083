import React, { useState } from "react";

import Swal from "sweetalert2";

import Modal from "~/components/Common/Modal";
import { CLINICA_ALEMANA } from "~/constants/salesSources";
import { updateOrCreateAppointmentPatientLastMile } from "~/utils/api/v2/appointmentPatient";

type AddPreadmisionCodeModalProps = {
  salesSource: string;
  isOpen: boolean;
  code: string;
  appointmentPatientId: string;
  onClose: () => void;
  setCode: (code: string) => void;
  onSuccess: () => void;
};

function AddPreadmisionCodeModal({
  salesSource,
  isOpen,
  code,
  appointmentPatientId,
  onClose,
  setCode,
  onSuccess,
}: AddPreadmisionCodeModalProps) {
  const [error, setError] = useState<string>("");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={<h2>Ingresa el {salesSource === CLINICA_ALEMANA ? "código de preadmisión" : "folio"}</h2>}
      content={
        <div className="mb-4 mt-2">
          Código:{" "}
          <input
            type="text"
            className="border-2 border-black rounded px-2 py-1 w-1/2"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <p className="text-error font-bold">{error}</p>
        </div>
      }
      onConfirm={async () => {
        const cleanCode = code.trim();
        if (cleanCode.length === 0) {
          setError("El código no puede estar vacío");
          return;
        }
        const res = await updateOrCreateAppointmentPatientLastMile(appointmentPatientId, cleanCode);
        if ("error" in res) {
          setError(res.error);
          return;
        }
        setCode("");
        onClose();
        onSuccess();
        Swal.fire({
          icon: "success",
          title: "Código agregado",
          text: "El código se agregó correctamente",
        });
      }}
    />
  );
}

export default AddPreadmisionCodeModal;
