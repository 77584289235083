import React, { forwardRef, useState, useImperativeHandle } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "rut.js";
import styled from "styled-components";
import Swal from "sweetalert2";

import Flex from "~/components/Containers/Flex";
import appointmentService from "~/utils/api/v1/appointmentService";
import { bankOptionsValues, paymentOptions } from "~/utils/constants/payments";
import { AppointmentV2, Payment } from "~/utils/interfaces/Appointment";

const CloseIconContainer = styled(IconButton)`
  position: absolute !important;
  top: 10px;
  right: 10px;
`;

const BorderedButton = styled(Button)`
  border-left: 1px solid rgba(3, 155, 229, 0.5);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

interface AddNewPaymentProps {
  appointment: AppointmentV2;
  appointmentId: string;
  modalOpen: boolean;
  paymentData: Payment;
  setPaymentData: React.Dispatch<React.SetStateAction<Payment>>;
  setError: (error: any) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  updatePaymentsData: (newPayments: string[]) => void;
}

// eslint-disable-next-line react/display-name
const AddNewPayment = forwardRef(
  (
    {
      appointment,
      appointmentId,
      modalOpen,
      paymentData,
      setPaymentData,
      setOpenModal,
      updatePaymentsData,
    }: AddNewPaymentProps,
    ref,
  ) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<any[]>([]);
    const appointmentIsInMexico = appointment?.country.toLowerCase() === "méxico";

    const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const file = event.target.files?.[0];
      if (file) {
        setPaymentData({ ...paymentData, receipt_image: file });
      }
    };

    const handleImagePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
      const clipboardItems = e.clipboardData.items;
      const items = [].slice
        .call(clipboardItems)
        .filter((item: DataTransferItem) => item?.type && item.type.startsWith("image/"));

      // No items were pasted
      if (items.length === 0) {
        Swal.fire({
          title: "Error",
          text: "Se intentó pegar algo que no es una imagen",
          icon: "error",
        });
      }

      const item = items[0] as DataTransferItem;
      const file = item.getAsFile();
      if (file) {
        setPaymentData({ ...paymentData, receipt_image: file });
      }
    };

    const handleOpen = () => {
      setOpenModal(true);
      setPaymentData({
        id: null,
        amount: 0,
        method: "In address",
        status: "Payment Pending",
        payment_gateway: "Cash",
        gateway_identifier: null,
        bank_name: "",
        document_number: "",
        receipt_image: null,
      });
    };

    const handleClose = () => {
      setValidationErrors([]);
      setOpenModal(false);
    };

    useImperativeHandle(ref, () => ({
      openDialog: () => {
        handleOpen();
      },
    }));

    const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (event.target.value === "") {
        setPaymentData({ ...paymentData, amount: "" as unknown as number });
        return;
      }
      const amountInput = parseInt(event.target.value, 10);
      setPaymentData({
        ...paymentData,
        amount: isNaN(amountInput) ? 0 : amountInput,
      });
    };

    const onChangeGatewayIdentifier = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setPaymentData({
        ...paymentData,
        gateway_identifier: event.target.value,
      });
    };

    const onChangePaymentMethod = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setPaymentData({ ...paymentData, method: event.target.value });
    };

    const onChangePaymentGateway = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const paymentGateway = event.target.value;
      if (paymentGateway === "Point of Sale") {
        setPaymentData({
          ...paymentData,
          payment_gateway: paymentGateway,
          method: "Credit Card",
          gateway_identifier: null,
          bank_name: null,
          receipt_image: null,
          document_number: null,
        });
      } else if (paymentGateway === "Cash") {
        setPaymentData({
          ...paymentData,
          payment_gateway: paymentGateway,
          method: "In address",
          gateway_identifier: null,
          bank_name: null,
          receipt_image: null,
          document_number: null,
        });
      } else if (paymentGateway === "MercadoPago") {
        setPaymentData({
          ...paymentData,
          payment_gateway: paymentGateway,
          method: "Credit Card",
          gateway_identifier: null,
        });
      } else if (paymentGateway === "Bank") {
        setPaymentData({
          ...paymentData,
          payment_gateway: paymentGateway,
          method: "Transfer",
          gateway_identifier: null,
        });
      } else if (paymentGateway === "Partner") {
        setPaymentData({
          ...paymentData,
          payment_gateway: paymentGateway,
          method: "End of month",
          gateway_identifier: null,
          bank_name: null,
          receipt_image: null,
          document_number: null,
        });
      } else {
        setPaymentData({ ...paymentData, payment_gateway: paymentGateway });
      }
    };

    const onChangePaymentStatus = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const paymentSuccessful = event.target.checked as boolean;
      if (paymentSuccessful) {
        setPaymentData({ ...paymentData, status: "Payment Successful" });
      } else {
        setPaymentData({
          ...paymentData,
          status: "Payment Pending",
          bank_name: null,
          receipt_image: null,
          document_number: null,
        });
      }
    };

    const validate = (): string[] => {
      const errors: string[] = [];
      if (paymentData.amount < 0) {
        errors.push("Monto debe ser mayor o igual a 0");
      }
      if (paymentData.payment_gateway === "Point of Sale" && !paymentData.gateway_identifier) {
        errors.push("Número de recibo es obligatorio");
      }
      if (
        paymentData.payment_gateway === "Bank" &&
        paymentData.status === "Payment Successful" &&
        needsReceipt &&
        !paymentData.bank_name
      ) {
        errors.push("Banco de quién paga es obligatorio");
      }
      if (needsReceipt && !paymentData.receipt_image) {
        errors.push("Foto de comprobante es obligatoria");
      }
      setValidationErrors(errors);
      return errors;
    };

    const handleSubmitPayment = async () => {
      const errors: string[] = validate();
      if (errors.length > 0) {
        return;
      }

      try {
        setLoading(true);
        const formData = new FormData();
        for (const [key, value] of Object.entries(paymentData)) {
          if (value) {
            formData.append(key, value);
          }
        }
        formData.append("source", "betty");
        const res = await appointmentService.registerPayment(appointmentId, formData);
        await appointmentService.fitDetails(appointmentId);
        updatePaymentsData(res.data.payments.map((payment: Payment) => payment.id));
        handleClose();
        Swal.fire({
          title: "Pago registrado",
          text: `El pago por ${paymentData.amount} ha sido registrado con éxito`,
          icon: "success",
        });
      } catch (e) {
        Swal.fire({
          title: "Error",
          text: `El pago no pudo ser registrado. ${e.message}`,
          icon: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    const payingWithPos = paymentData.payment_gateway === "Point of Sale";
    const payingWithMP = paymentData.payment_gateway === "MercadoPago";
    const paymentSuccessful = paymentData.status === "Payment Successful";
    const needsReceipt =
      ["Bank", "MercadoPago"].includes(paymentData.payment_gateway) && paymentData.status === "Payment Successful";

    return (
      <>
        <BorderedButton
          variant="text"
          color="primary"
          size="small"
          onClick={handleOpen}
        >
          Nuevo pago
        </BorderedButton>
        <Dialog
          open={modalOpen}
          onClose={handleClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            Nuevo pago
            <CloseIconContainer
              aria-label="close"
              onClick={handleClose}
              edge="end"
              size="small"
            >
              <CloseIcon />
            </CloseIconContainer>
          </DialogTitle>
          <DialogContent>
            {loading ? (
              <Flex
                justify="center"
                align="center"
                padding="3rem"
              >
                <CircularProgress />
              </Flex>
            ) : (
              <DialogContentText>
                {validationErrors.length > 0 && (
                  <Typography
                    variant="body2"
                    color="error"
                  >
                    {validationErrors.map((error) => (
                      <div key={error}>{error}</div>
                    ))}
                  </Typography>
                )}
                <Flex direction="column">
                  <Typography component="div">
                    <FormLabel id="pos-card">Estado</FormLabel>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>Pendiente</Grid>
                      <Grid item>
                        <Switch
                          color="primary"
                          value={paymentSuccessful}
                          onChange={onChangePaymentStatus}
                          checked={paymentSuccessful}
                        />
                      </Grid>
                      <Grid item>Pagado</Grid>
                    </Grid>
                  </Typography>
                  <TextField
                    id="amount"
                    type="number"
                    onChange={onChangeAmount}
                    value={paymentData.amount}
                    helperText="Monto a pagar *"
                  />
                </Flex>
                <Flex>
                  <Flex
                    direction="column"
                    gap="6px"
                    padding="12px 0"
                    style={{ width: "100%" }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="payment-gateway">Plataforma de pago</InputLabel>
                      <Select
                        labelId="payment-gateway"
                        id="payment-gateway"
                        value={paymentData.payment_gateway}
                        label="Plataforma de pago"
                        onChange={onChangePaymentGateway}
                      >
                        {paymentOptions.map((option) => {
                          if (appointmentIsInMexico) {
                            return (
                              <MenuItem
                                key={`payment-option-${option.name}`}
                                value={option.value}
                              >
                                {option.name}
                              </MenuItem>
                            );
                          } else {
                            return option.locale === "chile" && <MenuItem value={option.value}>{option.name}</MenuItem>;
                          }
                        })}
                      </Select>
                    </FormControl>
                    {payingWithPos && (
                      <>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="pos-card"
                            defaultValue="Credit card"
                            name="radio-buttons-group"
                            value={paymentData.method}
                            onChange={onChangePaymentMethod}
                          >
                            <FormControlLabel
                              value="Credit Card"
                              control={<Radio />}
                              label="Tarjeta de crédito"
                            />
                            <FormControlLabel
                              value="Debit Card"
                              control={<Radio />}
                              label="Tarjeta de débito"
                            />
                          </RadioGroup>
                        </FormControl>
                        <TextField
                          id="gateway_identifier"
                          type="string"
                          onChange={onChangeGatewayIdentifier}
                          value={paymentData.gateway_identifier}
                          helperText="Número de recibo *"
                        />
                      </>
                    )}
                    {payingWithMP && (
                      <>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="pos-card"
                            defaultValue="Credit card"
                            name="radio-buttons-group"
                            value={paymentData.method}
                            onChange={onChangePaymentMethod}
                          >
                            <FormControlLabel
                              value="Credit Card"
                              control={<Radio />}
                              label="Tarjeta de crédito"
                            />
                            <FormControlLabel
                              value="Webpay Plus"
                              control={<Radio />}
                              label="Webpay"
                            />
                          </RadioGroup>
                        </FormControl>
                      </>
                    )}
                    {needsReceipt && (
                      <>
                        <FormControl fullWidth>
                          <InputLabel>Banco</InputLabel>
                          <Select
                            value={paymentData.bank_name}
                            label="Banco de la transferencia"
                            onChange={(e) => {
                              setPaymentData({
                                ...paymentData,
                                bank_name: e.target.value as string,
                              });
                            }}
                            placeholder="Seleccione un tipo de banco"
                          >
                            {bankOptionsValues[appointmentIsInMexico ? "mx" : "cl"].map((option) => (
                              <MenuItem
                                key={option.name}
                                value={option.value}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {!appointmentIsInMexico && (
                          <FormControl fullWidth>
                            <TextField
                              id="document_number"
                              type="string"
                              onChange={(e) => {
                                setPaymentData({
                                  ...paymentData,
                                  document_number: format(e.target.value),
                                });
                              }}
                              value={paymentData.document_number}
                              label="RUT de quién pagó"
                              placeholder="Ejemplo: 11.111.111-1"
                            />
                          </FormControl>
                        )}
                        <Flex
                          direction="column"
                          padding="1rem 0"
                        >
                          <Typography
                            component="p"
                            className="text-sm text-examedi-gray-3"
                          >
                            Comprobante de pago
                          </Typography>
                          <Flex
                            direction="row"
                            padding="0"
                            justify="space-between"
                          >
                            <Input
                              type="text"
                              id="image-paste"
                              name="image-drop"
                              placeholder="Pegar la imagen aquí..."
                              className="w-1/2"
                              onPaste={handleImagePaste}
                              value={undefined}
                            />
                            <div>
                              <Button
                                color="primary"
                                variant="contained"
                                component="div"
                                className="w-full"
                              >
                                {paymentData.receipt_image ? "Subir otro comprobante" : "Subir comprobante"}
                              </Button>
                              <Input
                                type="file"
                                id="payment-receipt"
                                name="payment-receipt"
                                style={{ display: "none" }}
                                disabled={loading}
                                inputProps={{ accept: "image/*" }}
                                onChange={handleImageSelect}
                              />
                            </div>
                          </Flex>
                          <div className="mt-3 text-sm text-clip">
                            Imagen
                            <span className="text-green-600">{paymentData.receipt_image?.name}</span>
                            lista para subir
                          </div>
                        </Flex>
                      </>
                    )}
                  </Flex>
                </Flex>
                <Flex
                  justify="center"
                  align="center"
                  padding="1rem"
                >
                  <ButtonGroup
                    variant="outlined"
                    color="primary"
                  >
                    <Button onClick={handleSubmitPayment}>Agregar pago</Button>
                  </ButtonGroup>
                </Flex>
              </DialogContentText>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  },
);

export default AddNewPayment;
