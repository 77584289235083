import React from "react";

import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import styled from "styled-components";

const StepperWrapper = styled.div`
  width: 100%;
`;

export default function MaterialStepper({ stepsLabels, completed }: { stepsLabels: Array<string>; completed: Array }) {
  return (
    <StepperWrapper>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={-1}
      >
        {stepsLabels.map((label, index) => {
          return (
            <Step key={label}>
              <StepButton completed={completed[index]}>{label}</StepButton>
            </Step>
          );
        })}
      </Stepper>
    </StepperWrapper>
  );
}
