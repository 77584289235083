import React, { useState } from "react";

import Swal from "sweetalert2";

import ActionButton from "~/components/Common/ActionButton";
import { addOperativeAppointmentExtraFiles } from "~/utils/api/v2/appointment";

type UploadOperativeExtraFilesProps = {
  appointmentId: string;
  source: string;
};

function UploadOperativeExtraFiles({ appointmentId, source }: UploadOperativeExtraFilesProps) {
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []) as File[];
    files.forEach((file: File) => {
      const filename = file.name;
      const parts = filename.split(".");
      const ext = parts[parts.length - 1];

      if (ext.toLowerCase() !== "pdf") {
        Swal.fire({
          icon: "error",
          title: "El archivo debe ser un PDF",
        });
        return false;
      }

      const MAX_FILE_SIZE_15MB = 15_000_000;
      if (file.size > MAX_FILE_SIZE_15MB) {
        Swal.fire({
          icon: "error",
          title: "El archivo no debe pesar mas de 15MB",
        });
        return false;
      }

      setFiles((prevFiles) => {
        return [...prevFiles, file];
      });
    });
  };

  const uploadFiles = async () => {
    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("source", source);
    try {
      await addOperativeAppointmentExtraFiles(appointmentId, formData);
      Swal.fire({
        icon: "success",
        title: "Archivos subidos correctamente",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error al subir los archivos",
        text: JSON.stringify(error.response?.data),
      });
    }
    setLoading(false);
    setFiles([]);
  };

  return (
    <div className="flex flex-col">
      <h4>Subir Resultados Extras</h4>
      <p>Formato: nombre_rutpaciente.pdf. Ej: carta_11.111.111-1.pdf</p>
      <div className="flex items-center">
        <input
          type="file"
          accept="application/pdf"
          multiple
          onChange={handleChange}
        />
        <ActionButton
          text="Subir"
          className="w-fit py-2 px-3"
          onClick={uploadFiles}
          disabled={files.length === 0 || loading}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default UploadOperativeExtraFiles;
