import React from "react";

import { Skeleton } from "antd";
import clsx from "clsx";

import { AppointmentV2 } from "~/utils/interfaces/Appointment";

const SECTION_ORIGIN: Record<string, string> = {
  "self-service": "self-service (examedi.com)",
  betty: "betty (offliner)",
  "laas-link": "laas-link (laas paciente)",
  "laas-platform": "laas-platform (laas cliente)",
  "medical-order": "medical-order (a través de orden médica en Examedi.com)",
  sdk: "sdk",
  "assisted-scheduling": "assisted-scheduling (agendamiento asistido en betty)",
  "manual-confirm": "manual-confirm (manual confirm origen desconocido)",
};

type AppointmentOriginProps = {
  isLoading?: boolean;
  appointment?: AppointmentV2 | null;
  className?: string;
};

function AppointmentOrigin({ isLoading, appointment, className }: AppointmentOriginProps) {
  return (
    <section className={clsx("w-full flex flex-col gap-y-1", className)}>
      <label className="text-g3 font-medium text-sm">Cita creada en</label>
      {isLoading && (
        <Skeleton.Input
          active
          className="!h-6"
        />
      )}
      {!isLoading && appointment && (
        <div className="select-all text-g1 text-md leading-5">
          {SECTION_ORIGIN[appointment?.section_origin] || appointment?.section_origin}
        </div>
      )}
      {!isLoading && !appointment && <div className="text-g1 text-md">Desconocido</div>}
    </section>
  );
}

export default AppointmentOrigin;
