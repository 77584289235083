import React, { useState } from "react";

import { Button, TextField } from "@material-ui/core";
import Swal from "sweetalert2";

import { appointmentPatientAPI } from "~/utils/api/v2";

interface SampleIdentifierEditorProps {
  sampleIdentifier: string;
  appointmentPatientId: string;
}

const SampleIdentifierEditor: React.FC<SampleIdentifierEditorProps> = ({ sampleIdentifier, appointmentPatientId }) => {
  const [identifier, setIdentifier] = useState<string>(sampleIdentifier);

  const handleIdentifierChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIdentifier(event.target.value);
  };

  const saveIdentifier = async (): Promise<void> => {
    try {
      const response = await appointmentPatientAPI.patch(appointmentPatientId, {
        sample_identifier: identifier,
      });
      Swal.fire({
        icon: "success",
        title: "Sample identifier updated successfully",
        text: `New identifier: ${response.data.sample_identifier}`,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed to update sample identifier",
        text: error instanceof Error ? error.message : String(error),
      });
    }
  };

  return (
    <div>
      <TextField
        label="Número de orden"
        value={identifier}
        onChange={handleIdentifierChange}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <Button
        color="primary"
        variant="contained"
        onClick={saveIdentifier}
      >
        Actualizar Número de orden
      </Button>
    </div>
  );
};

export default SampleIdentifierEditor;
