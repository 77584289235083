import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";

import Bold from "~/components/Typography/Bold";
import appointmentService from "~/utils/api/v1/appointmentService";
import fetch from "~/utils/api/v1/fetchHelper";

interface Props {
  id: string;
  isFactura: boolean;
  setError: (error: any) => void;
}

const BoletaOrFactura = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isFactura, setIsFactura] = useState<boolean>(props.isFactura);

  const handleClick = async () => {
    fetch(setLoading, props.setError, async () => {
      await appointmentService.updateAppointment(props.id, { is_factura: !isFactura });
      setIsFactura((state) => !state);
    });
  };

  return (
    <Grid
      container
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <p>
          <Bold>Boleta</Bold>
        </p>
      </Grid>
      <Grid item>
        <Switch
          checked={isFactura}
          onChange={handleClick}
          disabled={loading}
        />
      </Grid>
      <Grid item>
        <p>
          <Bold>Factura</Bold>
        </p>
      </Grid>
    </Grid>
  );
};

export default BoletaOrFactura;
