import React, { useMemo } from "react";

import { Skeleton, Tooltip } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FaInfoCircle } from "react-icons/fa";

import ExternalLinkIcon from "~/components/icons/ExternalLinkIcon";
import { DEFAULT_TIMEZONE, TIMEZONE_TO_COUNTRY } from "~/utils/data/constants";
import { AppointmentV2 } from "~/utils/interfaces/Appointment";

type AppointmentHeadProps = {
  isLoading?: boolean;
  appointment: AppointmentV2 | null;
  className?: string;
};

function AppontmentBasicData({ isLoading, appointment, className }: AppointmentHeadProps) {
  const {
    apptPatientId,
    apptPatientName,
    apptScheduleDate,
    targetResidenceType,
    apartmentNumber,
    address,
    comuna,
    region,
    country,
    targetAddressAdditionalInfo,
  } = useMemo(() => {
    if (appointment) {
      const apptPatientName = appointment.patient?.full_name || "Sin datos";
      const startDate = moment(appointment.local_begin_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm");
      const endDate = moment(appointment.local_work_period_max_lateness, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
      let timezone = DEFAULT_TIMEZONE;
      if (appointment.safe_timezone) {
        timezone = (TIMEZONE_TO_COUNTRY as Record<string, string>)[appointment.safe_timezone];
      }
      const apptScheduleDate = startDate + " - " + endDate + " - " + timezone;
      return {
        apptPatientId: appointment.patient?.id,
        appPatientEmail: appointment.patient?.email,
        apptPatientName,
        apptScheduleDate,
        targetResidenceType: appointment.target_residence_type,
        apartmentNumber: appointment.target_apartment_number,
        address: appointment.address_line_1,
        comuna: appointment.comuna,
        region: appointment.region,
        country: appointment.country,
        targetAddressAdditionalInfo: appointment.target_address_additional_info,
      };
    }
    return {};
  }, [appointment]);

  return (
    <div className={clsx("flex flex-col gap-y-5", className)}>
      <div className="flex flex-col gap-y-1 gap-x-3">
        <div className="w-full flex items-center gap-x-2">
          <label className="text-g3 font-medium text-sm">Usuario</label>
          <Tooltip
            title={
              <div className="w-full">
                <div>
                  Es el usuario de la cuenta que ha creado de la cita y que no necesariamente podria ser el paciente.
                </div>
                <div>Él o los pacientes de la cita se desplegaran más abajo.</div>
              </div>
            }
            placement="right"
          >
            <FaInfoCircle className="fill-g2 h-4 w-4" />
          </Tooltip>
        </div>
        {isLoading && (
          <div className="w-full flex flex-col gap-y-0.5">
            <Skeleton.Input
              active
              className="!w-110 !h-5"
            />
          </div>
        )}
        {!isLoading && (
          <>
            <div className="flex gap-x-2 items-center">
              <span className="select-all text-g1 text-md leading-5">{apptPatientName}</span>
              {appointment?.patient && (
                <Tooltip
                  title="Ir al perfil del usuario"
                  placement="right"
                >
                  <a
                    href={"/users/" + apptPatientId}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLinkIcon className="h-4 w-4 fill-gray-2 hover:fill-blue-strong transition-[fill]" />
                  </a>
                </Tooltip>
              )}
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col gap-y-1 gap-x-3">
        <label className="text-g3 font-medium text-sm">Fecha de la cita</label>
        {isLoading && (
          <Skeleton.Input
            active
            className="!w-110 !h-5"
          />
        )}
        {!isLoading && <div className="select-all text-g1 text-md leading-5">{apptScheduleDate}</div>}
      </div>

      <div className="flex flex-col gap-y-1 gap-x-3">
        <label className="text-g3 font-medium text-sm">
          Dirección {targetResidenceType && <span>({targetResidenceType === "house" ? "Casa" : "Departamento"})</span>}
        </label>
        {isLoading && (
          <div className="flex flex-col gap-y-0.5">
            <Skeleton.Input
              active
              className="!w-48 !min-w-0 !h-4"
            />
            <Skeleton.Input
              active
              className="!w-36 !min-w-0 !h-4"
            />
            <Skeleton.Input
              active
              className="!w-60 !min-w-0 !h-4"
            />
            <Skeleton.Input
              active
              className="!w-20 !min-w-0 !h-4"
            />
          </div>
        )}
        {!isLoading && (
          <>
            <div className="leading-5 flex flex-col text-g1 text-md">
              <div className="select-all w-fit">{address}</div>
              {targetResidenceType == "apartment" && <div className="select-all w-fit">Depto. {apartmentNumber}</div>}
              <div className="select-all w-fit">{comuna}</div>
              <div className="select-all w-fit">{region}</div>
              <div className="select-all w-fit">{country}</div>
            </div>
            <div className="text-g3 text-md mt-4">
              Información adicional:
              <div className="text-g1">{targetAddressAdditionalInfo}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AppontmentBasicData;
