export async function compressFile(file: File) {
  return new Promise<File>((resolve) => {
    new Compressor(file, {
      quality: 0.2,
      success(result: File) {
        resolve(result);
      },
    });
  });
}
